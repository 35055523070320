import { IntroController } from "./intro_controller"

export default class extends IntroController {
  setDontShowAgainCookie() {
    this.intro.setOption("dontShowAgainCookie", `introjs-dontShowAgain-dashboard-${this.userIdValue}`)
  }

  addSteps() {
    // If the user is on a mobile device, toggle the hamburger menu when the user navigates the intro
    this.toggleHamburgerMenu()

    this.intro.addStep({
      title: "Welcome to FinCEN Report",
      intro: "Let's get started with a quick introduction to your dashboard."
    })

    if (this.isMobile) {
      this.intro.addStep({
        element: "#hamburger",
        title: "The Menu",
        intro: "Use the hamburger menu to navigate the dashboard."
      })
    }

    this.intro.addSteps([
      {
        element: `${this.navId} #my-info`,
        title: "Personal Information & Identifying Documents",
        intro: "Provide personal contact information that will be used in all of your reports for all of your companies.\
          Upload the appropriate identifying documents to verify your identity.\
          These documents will be submitted with your beneficial ownership report(s)."
      },
      {
        element: `${this.navId} #my-companies`,
        title: "Your First Company",
        intro: "Set up or join your first company.\
          After you have setup or joined a company you or the admin of the company can file a beneficial ownership report."
      },
      {
        element: "#start-intro",
        title: "Thats it!",
        intro: "You can restart this introduction any time if you need to revisit these three quick steps."
      }
    ])
  }

  toggleHamburgerMenu() {
    if (this.isMobile) {
      this.intro.onbeforechange((element) => {
        if (element.closest("#myLinks")?.checkVisibility() === false) {
          document.querySelector("#hamburger").click()
        } else if (document.querySelector("#myLinks").checkVisibility() && element.closest("#myLinks") === null) {
          document.querySelector("#hamburger").click()
        }
      })
    }
  }

  get isMobile() {
    return document.querySelector(".topnav").checkVisibility()
  }

  get navId() {
    return this.isMobile ? ".topnav" : "#sidebar"
  }
}
