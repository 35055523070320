import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	connect() {
		this.element.addEventListener("input", this.formatEin.bind(this));
	}

	formatEin() {
		let cleanedInput = this.element.value.replace(/\D/g, "");
		// Format the EIN with a dash after the second number
		let formattedEin = cleanedInput.replace(/^(\d{2})/, "$1-");
		formattedEin = formattedEin.substring(0, 10);

		this.element.value = formattedEin;
	}
}
