import { IntroController } from "./intro_controller"

export default class extends IntroController {
  setDontShowAgainCookie() {
    this.intro.setOption("dontShowAgainCookie", `introjs-dontShowAgain-companies-${this.userIdValue}`)
  }

  addSteps() {
    this.intro.addSteps([
      {
        intro: "Welcome to the My Companies page.\
          Here you can view all the companies you are associated with.\
          If you want to view the complete guide to working with companies please see the following document:\
          <a href='https://docs.google.com/presentation/d/1xGIDAsCok8TOUsZAtBcAfQX61PaJkbb85Sg9KLCo_x4' target='_blank'><b>Company Admin Handbook</b></a>"
      },
      {
        element: ".tab-menu",
        intro: "You can switch between your companies and invitations using these tabs."
      },
      {
        element: "a.float-right",
        intro: "You can add a new company by using this button."
      },
      {
        element: "thead",
        intro: "In this table you can see all the companies you are associated with including their name, type, state of formation, your roles, and the filing due date.\
          You can click on a specific company to view this company's details, add new members, and manage existing members."
      },
      {
        element: "input[type='search']",
        intro: "You can search for your companies by name, reference id or file number by entering information here and pressing the Enter key."
      },
      {
        element: "a.sort_link",
        intro: "You can also sort the companies by name, company type, and organization state by using these buttons."
      },
      {
        element: "#start-intro",
        title: "Thats it!",
        intro: "You can restart this walkthrough any time if you need to revisit this information."
      }
    ])
  }
}
