import { IntroController } from "./intro_controller"

export default class extends IntroController {
  setDontShowAgainCookie() {
    this.intro.setOption("dontShowAgainCookie", `introjs-dontShowAgain-company-${this.userIdValue}`)
  }

  addSteps() {
    this.intro.addSteps([
      {
        title: "Welcome to FinCEN Report",
        intro: "Let's get started with a quick introduction to your company page.\
          Each company you are a member of has a page like this one."
      },
      {
        element: ".company-action-buttons",
        title: "Important Actions",
        intro: "Use these buttons to invite new members, edit the company information or leave the company."
      },
      {
        element: ".tab-menu",
        title: "Sections of your Company",
        intro: "Use these buttons to look at each section of the company information.\
          The default page is General Information, you can also access Billing, Members and Pending Invitations."
      },
      {
        element: ".status_information .card-header",
        title: "Company Status",
        intro: "This section shows the status of your company.\
          If there are any issues that prevent your company from filing you will see the details here.\
          Once all warnings are resolved you can file a beneficial ownership report."
      },
      {
        element: "#previous-filings",
        title: "Your Previous Filings",
        intro: "This section shows all of the beneficial ownership reports that have been filed for this company.\
          You can view the details of each report here."
      },
      {
        element: "#start-intro",
        title: "Thats it!",
        intro: "You can restart this introduction any time if you need to revisit these hints."
      }
    ])
  }
}
