import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "formationCountry",
    "formationCountryNoteForNonUsTerritory",
    "orgstate",
  ]

  static values = {
    usTerritoryIds: Array,
  }

  connect() {
    this.updateFormationCountryRequirements()
  }

  updateFormationCountryRequirements() {
    const isUsTerritory = this.isFormationCountryUsTerritory()

    this.formationCountryNoteForNonUsTerritoryTarget.classList.toggle(
      "hidden",
      isUsTerritory
    )
    this.orgstateTarget.required = !isUsTerritory
  }

  isFormationCountryUsTerritory() {
    return this.usTerritoryIdsValue.some(
      (id) => id.toString() === this.formationCountryTarget.value
    )
  }
}
