import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["kindField", "guideForField", "urlField", "fileField", "externalUrlField", "slugInput"];

	connect() {
		const mediaFormat = document.querySelector("input[name='guide[media_format]']:checked").value;
		this.showAndHideGuideFields(mediaFormat);
	}

	changeMediaFormat(event) {
		const mediaFormat = event.target.value;
		this.showAndHideGuideFields(mediaFormat);
	}

	showAndHideGuideFields(mediaFormat) {
		if (mediaFormat === "url") {
			this.kindFieldTarget.classList.add("hidden");
			this.fileFieldTarget.classList.add("hidden");
			this.urlFieldTarget.classList.remove("hidden");
			this.guideForFieldTarget.classList.remove("hidden");
			this.externalUrlFieldTarget.classList.add("hidden");
		} else if (mediaFormat === "pdf") {
			this.kindFieldTarget.classList.remove("hidden");
			this.fileFieldTarget.classList.remove("hidden");
			this.urlFieldTarget.classList.add("hidden");
			this.guideForFieldTarget.classList.add("hidden");
			this.externalUrlFieldTarget.classList.add("hidden");
		} else if (mediaFormat === "external_link") {
			this.kindFieldTarget.classList.add("hidden");
			this.fileFieldTarget.classList.add("hidden");
			this.urlFieldTarget.classList.add("hidden");
			this.guideForFieldTarget.classList.add("hidden");
			this.externalUrlFieldTarget.classList.remove("hidden");
		}
	}

	handleSlugChange(event) {
		const alert = document.querySelector(".slug-alert");
		let value = event.target.value;
		alert?.classList.remove("hidden");

		// Convert to lowercase and replace invalid characters
		value = value.toLowerCase().replace(/[^a-z0-9\-]/g, "");

		// Trim the slug to 32 characters if necessary
		if (value.length > 32) {
			value = value.substring(0, 32);
			alert.classList.add("hidden"); // Hide alert if within limit
		}

		// Update the slug field with the formatted value
		this.slugInputTarget.value = value;
	}
}
