import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["beforeCta", "afterCta", "formationDate"];

	connect() {
		this.beforeCtaTarget.addEventListener("change", this.toggleCheckbox.bind(this, "before"));
		this.afterCtaTarget.addEventListener("change", this.toggleCheckbox.bind(this, "after"));
		this.updateFormationDateField();
	}

	toggleCheckbox(selected) {
		if (selected === "before") {
			if (this.beforeCtaTarget.checked) {
				this.afterCtaTarget.checked = false;
			}
		} else if (selected === "after") {
			if (this.afterCtaTarget.checked) {
				this.beforeCtaTarget.checked = false;
			}
		}
		this.updateFormationDateField();
	}

	updateFormationDateField() {
		const isBeforeChecked = this.beforeCtaTarget.checked;
		const isAfterChecked = this.afterCtaTarget.checked;

		if (isBeforeChecked || isAfterChecked) {
			this.formationDateTarget.disabled = true;
		} else {
			this.formationDateTarget.disabled = false;
		}
	}
}
