import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["emailField", "companyField", "modal"];

	checkUser(event) {
		event.preventDefault();
		const email = this.emailFieldTarget.value; // Get the email value
		const company = this.companyFieldTarget.value; // Get the company value

		fetch("/company/check_member", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": this.csrfToken,
			},
			body: JSON.stringify({ email: email, company: company }),
		})
			.then((response) => response.json())
			.then((data) => {
				const modalSubmitBtn = this.modalTarget.querySelector("#modalSubmitBtn");
				modalSubmitBtn.classList?.remove("hidden");
				if (data.member_exists) {
					this.showModal("This user already exists and is a member of this company.");
					// hide the submit button inside the modal
					modalSubmitBtn.classList.add("hidden");
				} else if (data.user_exists) {
					this.showModal("This user already exists. Would you like to add this user to the company?");
				} else {
					this.submitWithDisabledFields(event);
				}
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	}

	showModal(message) {
		const modalElement = this.modalTarget;
		modalElement.querySelector(".modal-body").textContent = message;
		$(modalElement).modal("show"); // Show the modal using Bootstrap's modal function
	}

	submitWithDisabledFields(event) {
		const formController = this.application.getControllerForElementAndIdentifier(this.element.closest("form"), "form");
		formController.submitWithDisabledFields(event); // Call submitWithDisabledFields from form controller
	}

	get csrfToken() {
		return document.querySelector("[name='csrf-token']")?.content;
	}
}
