import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["documentTypeField", "countryField", "stateField", "tribeField", "otherTribeField"];
	static values = { otherTribeId: String, usTerritoryIds: Array, usId: Number };

	connect() {
		this.initializeFields();
	}

	initializeFields() {
		console.log("myDocumentsFieldsController initialized", this.stateFieldTarget.value);
		this.countryFieldTarget.disabled = false;

		const documentType = this.documentTypeFieldTarget.value;
		const stateValue = this.stateFieldTarget.value;
		const tribeValue = this.tribeFieldTarget.value;
		const otherTribeValue = this.otherTribeFieldTarget.value;

		const showElement = (element) => element.classList.remove("hidden");
		const hideElement = (element) => element.classList.add("hidden");

		const toggleFields = (showStateField, showTribeField, showOtherTribeField) => {
			showStateField ? showElement(this.stateFieldTarget.parentElement) : hideElement(this.stateFieldTarget.parentElement);
			showTribeField ? showElement(this.tribeFieldTarget.parentElement) : hideElement(this.tribeFieldTarget.parentElement);
			showOtherTribeField ? showElement(this.otherTribeFieldTarget.parentElement) : hideElement(this.otherTribeFieldTarget.parentElement);
		};

		if (documentType === "") {
			hideElement(this.countryFieldTarget.parentElement);
			toggleFields(false, false, false);
		} else {
			showElement(this.countryFieldTarget.parentElement);

			switch (documentType) {
				case "1": // State issued Driver's License
					toggleFields(true, false, false);
					break;
				case "2": // US Passport
					this.countryFieldTarget.disabled = true;
					this.countryFieldTarget.value = this.usIdValue;
					toggleFields(false, false, false);
					break;
				case "3": // Foreign Passport
					toggleFields(false, false, false);
					break;
				case "4": // State/Local or Tribe
					if (stateValue !== "" && stateValue !== "0") {
						toggleFields(true, false, false);
					} else if (tribeValue !== "" && tribeValue != 1187) {
						// Id: 1187 Other (please add description)
						toggleFields(false, true, false);
					} else if (tribeValue == 1187) {
						toggleFields(false, true, true);
					}
					break;
				default:
					toggleFields(true, true, false);
			}
		}
	}

	selectDocumentType(e) {
		const docType = e.target.value;
		this.countryFieldTarget.disabled = false;
		this.countryFieldTarget.parentElement.classList.remove("hidden");

		if (docType === "") {
			this.toggleFields({ showStateField: false, showTribeField: false, showOtherTribeField: false });
		} else if (docType === "1") {
			//state issued id
			this.toggleFields({ showStateField: true, showTribeField: false, showOtherTribeField: false });
		} else if (docType === "2") {
			//US Passport
			this.countryFieldTarget.parentElement.value = this.usIdValue;
			this.countryFieldTarget.disabled = true;
			this.toggleFields({ showStateField: false, showTribeField: false, showOtherTribeField: false });
		} else if (docType === "3") {
			//Foreign Passport
			this.toggleFields({ showStateField: false, showTribeField: false, showOtherTribeField: false });
		} else if (docType === "4") {
			//State/Local or Tribe
			this.toggleFields({ showStateField: true, showTribeField: true, showOtherTribeField: false });
		}
	}

	selectCountry(e) {
		if (this.documentTypeFieldTarget.value === "3") {
			this.toggleFields({ showStateField: false, showTribeField: false, showOtherTribeField: false });
		} else if (e.target.value === "" || this.usTerritoryIdsValue.includes(Number(e.target.value)) || this.usIdValue !== Number(e.target.value)) {
			this.toggleFields({ showStateField: false, showTribeField: false, showOtherTribeField: false });
		} else {
			this.toggleFields({ showStateField: true, showTribeField: true, showOtherTribeField: false });
		}
	}

	selectState(e) {
		if (e.target.value === "") {
			this.toggleFields({ showTribeField: true, showOtherTribeField: false });
		} else {
			this.toggleFields({ showTribeField: false, showOtherTribeField: false });
		}
	}

	selectTribe(e) {
		if (e.target.value === "") {
			this.toggleFields({ showStateField: true, showOtherTribeField: false });
		} else if (e.target.value === this.otherTribeIdValue) {
			this.toggleFields({ showStateField: false, showOtherTribeField: true });
		} else {
			this.toggleFields({ showStateField: false, showOtherTribeField: false });
		}
	}

	toggleFields({ showStateField, showTribeField, showOtherTribeField }) {
		if (showStateField === true) {
			this.stateFieldTarget.parentElement.classList.remove("hidden");
			this.stateFieldTarget.value = "";
		} else if (showStateField === false) {
			this.stateFieldTarget.parentElement.classList.add("hidden");
			this.stateFieldTarget.value = "";
		}
		if (showTribeField === true) {
			this.tribeFieldTarget.parentElement.classList.remove("hidden");
			this.tribeFieldTarget.value = "";
		} else if (showTribeField === false) {
			this.tribeFieldTarget.parentElement.classList.add("hidden");
			this.tribeFieldTarget.value = "";
		}
		if (showOtherTribeField === true) {
			this.otherTribeFieldTarget.parentElement.classList.remove("hidden");
			this.otherTribeFieldTarget.value = "";
		} else if (showOtherTribeField === false) {
			this.otherTribeFieldTarget.parentElement.classList.add("hidden");
			this.otherTribeFieldTarget.value = "";
		}
	}
}
