import { Controller } from "@hotwired/stimulus"
import { debounce } from 'lodash-es'

export default class extends Controller {
  connect () {
    this.form = this.element
  }

  submit () {
    if (this.form.requestSubmit) {
      this.form.requestSubmit()
    } else {
      this.form.dispatchEvent(new CustomEvent('submit', { bubbles: true }))
    }
  }

  submitWithDebounce = debounce(this.submit, 300)

  submitOnEnter (event) {
    if (event.keyCode == 13) {
      this.submitWithDebounce()
    }
  }

  submitWithDisabledFields (e) {
    e.preventDefault()
    var persistedDisabledFields = this.disabledFields
    // enable disabled fields to allow them to be submitted
    persistedDisabledFields.forEach((element) => element.disabled = false)
    this.submit()
    // disable fields again if submission fails
    persistedDisabledFields.forEach((element) => element.disabled = true)
  }

  get disabledFields() {
    return this.form.querySelectorAll("input[disabled], select[disabled]")
  }
}
