import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['output', 'input']

  readURL() {
    const input = this.inputTarget
    const output = this.outputTarget
    const [file] = input.files

    if (file) {
      const reader = new FileReader()
      if (file.type === 'application/pdf') {
        reader.onload = async () => {
          const typedArray = new Uint8Array(reader.result)
          const loadingTask = pdfjsLib.getDocument(typedArray)
          const pdf = await loadingTask.promise
          const page = await pdf.getPage(1)

          const scale = 1
          const viewport = page.getViewport({ scale })
          // Support HiDPI-screens.
          const outputScale = window.devicePixelRatio || 1

          //
          // Prepare canvas using PDF page dimensions
          //
          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')

          canvas.width = Math.floor(viewport.width * outputScale)
          canvas.height = Math.floor(viewport.height * outputScale)

          const transform =
            outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null

          //
          // Render PDF page into canvas context
          //
          const renderContext = {
            canvasContext: context,
            transform,
            viewport,
          }
          await page.render(renderContext).promise
          canvas.toBlob((blob) => {
            const url = URL.createObjectURL(blob)
            output.onload = () => {
              URL.revokeObjectURL(url)
            }
            output.dataset.originalSrc ??= output.getAttribute('src') ?? ''
            output.src = url
          })
        }
        reader.readAsArrayBuffer(file)
      } else {
        reader.onload = () => {
          output.dataset.originalSrc ??= output.getAttribute('src') ?? ''
          output.src = reader.result
        }

        reader.readAsDataURL(file)
      }
    } else {
      output.src = output.dataset.originalSrc ?? ''
    }
  }
}
