import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="gtag"
export default class extends Controller {
  sendEvent({ params: { event, payload } }) {
    if (!this.isProdEnvironment) return

    event.split(",").forEach(event_name => {
      if (event_name === "") return
      gtag("event", event_name, payload)
    })
  }

  get isProdEnvironment() {
    return document.head.querySelector("meta[name=rails_env]").content === "production"
  }
}
