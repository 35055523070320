import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["successInput", "boirIdInput", "trackingIdInput", "fincenIdInput", "filingErrorsInput", "boirResultInput"];

	adjustRequiredFields(event) {
		const successRequired = document.querySelectorAll(".success-required");
		const errorRequired = document.querySelector(".error-required");

		if (event.target.checked) {
			this.boirIdInputTarget.required = true;
			this.trackingIdInputTarget.required = true;
			this.fincenIdInputTarget.required = true;
			this.boirResultInputTarget.required = true;
			this.filingErrorsInputTarget.required = false;

			successRequired.forEach((element) => {
				element.classList.remove("hidden");
			});
			errorRequired.classList.add("hidden");
		} else {
			this.filingErrorsInputTarget.required = true;
			this.boirIdInputTarget.required = false;
			this.trackingIdInputTarget.required = false;
			this.fincenIdInputTarget.required = false;
			this.boirResultInputTarget.required = false;

			successRequired.forEach((element) => {
				element.classList.add("hidden");
			});
			errorRequired.classList.remove("hidden");
		}
	}
}
