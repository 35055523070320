import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["list", "item", "remove", "template"];

	add() {
		const item = this.templateTarget.content.cloneNode(true);
		this.listTarget.appendChild(item);
		console.log("add", this.listTarget);
	}

	remove(event) {
		const index = this.removeTargets.findIndex((n) => n === event.currentTarget);
		const item = this.itemTargets[index];
		item.remove();
	}
}
