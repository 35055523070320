import { Controller } from '@hotwired/stimulus'
import Tribute from 'tributejs'
import Trix from 'trix'

// Connects to data-controller="trix-mentions"
export default class extends Controller {
  static values = {
    urlTemplate: String,
  }

  connect() {
    this.initializeTribute()
  }

  initializeTribute() {
    this.tribute = new Tribute({
      values: this._fetch.bind(this),
      allowSpaces: true,
      requireLeadingSpace: false,
    })
    this.tribute.attach(this.element)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    this.element.addEventListener('tribute-replaced', this._replaced.bind(this))
  }

  get editor() {
    return this.element.editor
  }

  _fetch(text, cb) {
    fetch(this.urlTemplateValue.replace(':q', encodeURIComponent(text)))
      .then((response) => response.json())
      .then(cb)
      .catch(() => cb([]))
  }

  _pasteHtml(html, startPos, endPos) {
    const position = this.editor.getPosition()
    this.editor.setSelectedRange([position - (endPos - startPos), position])
    this.editor.deleteInDirection('backward')
  }

  _replaced(event) {
    const { sgid, content } = event.detail.item.original
    const attachment = new Trix.Attachment({ sgid, content })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(' ')
  }
}
