import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	connect() {
		const addressType = document.querySelector("input[name='user_properties[address_type]']:checked").value;
		this.showAndHideAddresses(addressType);
	}

	changeAddressType(event) {
		const addressType = event.target.value;
		this.showAndHideAddresses(addressType);
	}

	showAndHideAddresses(addressType) {
		const homeSection = document.getElementById("home_address");
		const workSection = document.getElementById("work_address");

		const mailingRequiredFields = document.querySelectorAll(".mailing-required-field");
		const workRequiredFields = document.querySelectorAll(".work-required-field");

		if (addressType === "home") {
			homeSection.classList.remove("hidden");
			workSection?.classList.add("hidden");

			mailingRequiredFields.forEach((element) => {
				element.required = true;
			});

			workRequiredFields.forEach((element) => {
				element.required = false;
			});
		} else if (addressType === "work") {
			homeSection.classList.add("hidden");
			workSection?.classList.remove("hidden");

			mailingRequiredFields.forEach((element) => {
				element.required = false;
			});

			workRequiredFields.forEach((element) => {
				element.required = true;
			});
		}
	}
}
