import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["documentType"];

	connect() {
		this.documentTypeChanged();
	}

	documentTypeChanged(event) {
		const issuingState = document.querySelector("#user_user_property_attributes_issuing_state_id");
		const issuingStateLabel = document.querySelector("#issuing_state_label");
		const issuingCountry = document.querySelector("#user_user_property_attributes_issuing_country_id");
		const issuingCountryLabel = document.querySelector("#issuing_country_label");
		const issuingOther = document.querySelector("#user_user_property_attributes_issuing_other_id");
		const issuingOtherLabel = document.querySelector("#issuing_other_label");
		const issuingText = document.querySelector("#user_user_property_attributes_issuing_other_text");
		const issuingOtherTextLabel = document.querySelector("#issuing_text_label");

		issuingState.classList.add("hidden");
		issuingStateLabel.classList.add("hidden");
		issuingCountry.classList.add("hidden");
		issuingCountryLabel.classList.add("hidden");
		issuingOther.classList.add("hidden");
		issuingOtherLabel.classList.add("hidden");
		issuingText.classList.add("hidden");
		issuingOtherTextLabel.classList.add("hidden");

		if (this.documentTypeTarget.value == 1) {
			issuingState.classList.remove("hidden");
			issuingStateLabel.classList.remove("hidden");
			issuingCountry.value = "233";
		} else if (this.documentTypeTarget.value == 2) {
			issuingCountry.value = "233";
		} else if (this.documentTypeTarget.value == 3) {
			issuingCountry.value = "";
			issuingCountryLabel.classList.remove("hidden");
			issuingCountry.classList.remove("hidden");
		} else if (this.documentTypeTarget.value == 4) {
			issuingCountry.value = "233";
			issuingCountry.classList.remove("hidden");
			issuingCountryLabel.classList.remove("hidden");
			issuingState.classList.remove("hidden");
			issuingStateLabel.classList.remove("hidden");
			issuingOther.classList.remove("hidden");
			issuingOtherLabel.classList.remove("hidden");
			issuingText.classList.remove("hidden");
			issuingOtherTextLabel.classList.remove("hidden");
		}
	}
}
