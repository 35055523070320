import { Controller } from "@hotwired/stimulus"

export class IntroController extends Controller {
  static values = { userId: Number, disableAutoStart: Boolean }

  connect() {
    if (this.isTestEnvironment) return
    this.intro = introJs()
    this.intro.setOption("dontShowAgain", true)
    this.setDontShowAgainCookie()
    this.addSteps()
    this.autoStart()
  }

  autoStart() {
    if (this.disableAutoStartValue === true) return
    this.intro.start()
  }

  start() {
    if (this.isTestEnvironment) return
    this.intro.setOption("dontShowAgain", false)
    this.intro.start()
  }

  get isTestEnvironment() {
    return document.head.querySelector("meta[name=rails_env]").content === "test"
  }
}
