import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="input-formatter"
export default class extends Controller {
  formatSSN(e) {
    // Only allow digits
    let formattedSSN = e.target.value.replace(/\D/g, "")
    // Limit the length of the string to 9 characters
    formattedSSN = formattedSSN.substring(0, 9)
    e.target.value = formattedSSN
  }

  formatForeign(e) {
    // Only allow alphanumeric
    let formattedForeign = e.target.value.replace(/[^a-zA-Z0-9]/g, "")
    // Limit the length of the string to 25 characters
    formattedForeign = formattedForeign.substring(0, 25)
    e.target.value = formattedForeign
  }
}
