import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.inputTargets.forEach(input => {
      // Ignore html5 validation if the field is required
      if (input.hasAttribute("required")) { input.removeAttribute("required") }

      input.addEventListener("input", (event) => this.clearError(event))
    })
  }

  validate(event) {
    let formIsValid = true
    let firstErrorInput = null

    this.inputTargets.forEach(input => {
      const errorElement = input.closest(".form-group").querySelector(".error-message")
      const errorMessage = input.getAttribute("data-validation-error-message")

      // Clear previous error state
      input.closest(".form-group").classList.remove("has-error")
      errorElement.textContent = ""

      // Skip validation if the field is disabled or hidden
      if (input.disabled || input.closest(".hidden")) return

      // Validate if the field is empty
      if (input.value.trim() === "") {
        // Prevent form submission
        event.preventDefault()
        formIsValid = false

        // Apply error styling and set error message
        input.closest(".form-group").classList.add("has-error")
        errorElement.textContent = errorMessage

        // Set the first error field to scroll to, if not already set
        if (!firstErrorInput) {
          firstErrorInput = input
        }
      }
    })

    // Scroll to the first error field if there is any invalid fields
    if (!formIsValid && firstErrorInput) {
      firstErrorInput.scrollIntoView({ behavior: "smooth", block: "center" })
      firstErrorInput.focus()
    }

    return formIsValid
  }

  clearError(event) {
    const input = event.target
    const errorElement = input.closest(".form-group").querySelector(".error-message")
    input.closest(".form-group").classList.remove("has-error")
    errorElement.textContent = ""
  }
}
