// See https://www.stimulus-components.com/docs/stimulus-checkbox-select-all/

import CheckboxSelectAll from "@stimulus-components/checkbox-select-all"

// Connects to data-controller="checkbox-select-all"
export default class extends CheckboxSelectAll {
  static targets = ["submitButton"]

  connect() {
    super.connect()

    if (this.hasSubmitButtonTarget) {
      this.toggleSubmitButton()
    }
  }

  toggleSubmitButton() {
    this.submitButtonTarget.disabled = this.checked.length === 0
  }

  checkAll(e) {
    e.preventDefault()

    this.checkboxTargets.forEach((checkbox) => checkbox.checked = true)
    this.refresh()
  }

  checkNone(e) {
    e.preventDefault()

    this.checkboxTargets.forEach((checkbox) => checkbox.checked = false)
    this.refresh()
  }

  toggle(e) {
    super.toggle(e)

    this.checkboxTargets.forEach((checkbox) => this.highlightRow(checkbox))
  }

  refresh() {
    super.refresh()

    this.checkboxTargets.forEach((checkbox) => this.highlightRow(checkbox))
  }

  highlightRow(checkbox) {
    checkbox.closest("tr").classList.toggle("table-primary", checkbox.checked)
  }
}
