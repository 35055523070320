import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["customerIdField", "responsiblePartyCheckbox"]

  connect() {
    if (this.customerIdFieldTarget.value) {
      this.responsiblePartyCheckboxTarget.disabled = false
    } else {
      this.responsiblePartyCheckboxTarget.disabled = true
    }
  }

  focusCustomerId(event) {
    if (event.target.value) {
      this.responsiblePartyCheckboxTarget.disabled = false
    } else {
      this.responsiblePartyCheckboxTarget.checked = false
      this.responsiblePartyCheckboxTarget.disabled = true
    }
  }
}
