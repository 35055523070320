import { Controller } from "@hotwired/stimulus"
import { Popover } from "bootstrap"

export default class extends Controller {
  static targets = ["content"]

  connect() {
    this.popover = new Popover(this.element, {
      content: this.contentTarget,
      html: true,
      trigger: "hover"
    })
  }

  toggle(e) {
    e.preventDefault()
    this.popover.toggle()
  }
}
