import { Controller } from "@hotwired/stimulus"
import { Tooltip } from 'bootstrap'

export default class extends Controller {
  static targets = ["tooltip", "source"]

  connect() {
    if (this.hasTooltipTarget) {
      this.tooltip = new Tooltip(this.tooltipTarget, { trigger: "manual" })
    }
  }

  show() {
    if (this.hasTooltipTarget) {
      this.tooltip.show()
    }
  }

  hide() {
    if (this.hasTooltipTarget) {
      this.tooltip.hide()
    }
  }

  copy(e) {
    navigator.clipboard.writeText(this.sourceTarget.value)

    if (this.hasTooltipTarget) {
      this.tooltip.hide()
      const originalText = this.tooltipTarget.dataset.originalTitle
      this.tooltipTarget.dataset.originalTitle = "Copied!"
      this.tooltip.show()
      setTimeout(() => {
        this.tooltip.hide()
        this.tooltipTarget.dataset.originalTitle = originalText
      }, 2000)
    }
  }
}
