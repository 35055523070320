import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="company-form--formation-fields"
export default class extends Controller {
	static targets = ["countryField", "stateField", "tribeField", "otherTribeField", "foreignCountryAlert"];
	static values = { otherTribeId: String, usTerritoryIds: Array, usId: Number };

	connect() {
		this.initializeFields();
	}

	initializeFields() {
		if (this.countryFieldTarget.value === "" || this.usTerritoryIdsValue.includes(Number(this.countryFieldTarget.value))) {
			return;
		} else if (this.usIdValue !== Number(this.countryFieldTarget.value)) {
			this.foreignCountryAlertTarget.classList.remove("d-none");
		}

		if (this.stateFieldTarget.value === "" && this.tribeFieldTarget.value === "") {
			this.stateFieldTarget.parentElement.classList.remove("d-none");
			this.tribeFieldTarget.parentElement.classList.remove("d-none");
		}

		if (this.stateFieldTarget.value !== "") {
			this.stateFieldTarget.parentElement.classList.remove("d-none");
		}

		if (this.tribeFieldTarget.value !== "") {
			this.tribeFieldTarget.parentElement.classList.remove("d-none");
		}

		if (this.tribeFieldTarget.value === this.otherTribeIdValue) {
			this.otherTribeFieldTarget.parentElement.classList.remove("d-none");
		}
	}

	selectCountry(e) {
		if (e.target.value === "" || this.usTerritoryIdsValue.includes(Number(e.target.value))) {
			this.toggleFields({ showStateField: false, showTribeField: false, showOtherTribeField: false });
		} else {
			this.toggleFields({ showStateField: true, showTribeField: true, showOtherTribeField: false });
		}

		if (e.target.value === "" || this.usTerritoryIdsValue.includes(Number(e.target.value)) || this.usIdValue === Number(e.target.value)) {
			this.foreignCountryAlertTarget.classList.add("d-none");
		} else {
			this.foreignCountryAlertTarget.classList.remove("d-none");
		}
	}

	selectState(e) {
		if (e.target.value === "") {
			this.toggleFields({ showTribeField: true, showOtherTribeField: false });
		} else {
			this.toggleFields({ showTribeField: false, showOtherTribeField: false });
		}
	}

	selectTribe(e) {
		if (e.target.value === "") {
			this.toggleFields({ showStateField: true, showOtherTribeField: false });
		} else if (e.target.value === this.otherTribeIdValue) {
			this.toggleFields({ showStateField: false, showOtherTribeField: true });
		} else {
			this.toggleFields({ showStateField: false, showOtherTribeField: false });
		}
	}

	toggleFields({ showStateField, showTribeField, showOtherTribeField }) {
		if (showStateField === true) {
			this.stateFieldTarget.parentElement.classList.remove("d-none");
			this.stateFieldTarget.value = "";
		} else if (showStateField === false) {
			this.stateFieldTarget.parentElement.classList.add("d-none");
			this.stateFieldTarget.value = "";
		}

		if (showTribeField === true) {
			this.tribeFieldTarget.parentElement.classList.remove("d-none");
			this.tribeFieldTarget.value = "";
		} else if (showTribeField === false) {
			this.tribeFieldTarget.parentElement.classList.add("d-none");
			this.tribeFieldTarget.value = "";
		}

		if (showOtherTribeField === true) {
			this.otherTribeFieldTarget.parentElement.classList.remove("d-none");
			this.otherTribeFieldTarget.value = "";
		} else if (showOtherTribeField === false) {
			this.otherTribeFieldTarget.parentElement.classList.add("d-none");
			this.otherTribeFieldTarget.value = "";
		}
	}
}
