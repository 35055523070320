import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	connect() {
		this.element.addEventListener("input", this.formatCompanyName.bind(this));
	}

	formatCompanyName() {
		// replaces characters that are not letters, numbers, or the following special characters: `*()={}[];:"<>\
		let cleanedInput = this.element.value.replace(/[`*()={}[\];:"<>\\]/g, "");
		this.element.value = cleanedInput;
	}
}
