import { Controller } from "@hotwired/stimulus"

/**
 * @example
 * <select
 *   id="country_id"
 *   data-controller="country-select"
 *   data-country-select-state-select-outlet="#state_id"
 *   data-action="country-select#updateStateSelect"
 * >
 *   <option value="" label=" "></option>
 *   <option value="1">Afghanistan</option>
 *   <option value="2" selected="selected">United States</option>
 * </select>
 * <select
 *   id="state_id"
 *   data-controller="state-select"
 *   data-state-select-country-select-outlet="#country_id"
 *   data-action="state-select#rememberSelection"
 * >
 *   <option value="" label=" "></option>
 *   <option value="1" data-country-id="1">Badakhshan</option>
 *   <option value="2" data-country-id="1">Badghis</option>
 *   <option value="3" data-country-id="2">Alabama</option>
 *   <option value="4" data-country-id="2" selected="selected">New York</option>
 * </select>
 */
export default class extends Controller {
  static outlets = ["state-select"]

  updateStateSelect() {
    this.stateSelectOutlets.forEach(n => n.updateOptions())
  }
}
