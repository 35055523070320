import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["taxIdTypeField", "einField", "ssnField", "foreignField", "foreignCountryField"]

  connect() {
    this.currentType = this.taxIdTypeFieldTarget.value
    this.show(this.currentType)
  }

  selectTaxIdType(event) {
    this.currentType = event.target.value
    this.show(this.currentType)
  }

  show(type) {
    if (type === "ein") {
      this.einFieldTarget.parentElement.classList.remove("d-none")
      this.ssnFieldTarget.parentElement.classList.add("d-none")
      this.foreignFieldTarget.parentElement.classList.add("d-none")
      this.foreignCountryFieldTarget.parentElement.classList.add("d-none")
      this.ssnFieldTarget.value = ""
      this.foreignFieldTarget.value = ""
      this.foreignCountryFieldTarget.value = ""
    } else if (type === "ssn_itin") {
      this.einFieldTarget.parentElement.classList.add("d-none")
      this.ssnFieldTarget.parentElement.classList.remove("d-none")
      this.foreignFieldTarget.parentElement.classList.add("d-none")
      this.foreignCountryFieldTarget.parentElement.classList.add("d-none")
      this.einFieldTarget.value = ""
      this.foreignFieldTarget.value = ""
      this.foreignCountryFieldTarget.value = ""
    } else if (type === "foreign") {
      this.einFieldTarget.parentElement.classList.add("d-none")
      this.ssnFieldTarget.parentElement.classList.add("d-none")
      this.foreignFieldTarget.parentElement.classList.remove("d-none")
      this.foreignCountryFieldTarget.parentElement.classList.remove("d-none")
      this.einFieldTarget.value = ""
      this.ssnFieldTarget.value = ""
    }
  }
}
