import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="company-form--address-fields"
export default class extends Controller {
  static targets = ["countryField", "stateField"]
  static values = { requiredFields: Boolean }

  connect() {
    this.initializeFields()
  }

  initializeFields() {
    this.init = true
    this.selectCountry()
    this.init = false
  }

  selectCountry() {
    switch (this.selectedCountryType) {
      case "US":
        this.enableStateField(true)
        break
      case "Territory":
        this.enableStateField(false)
        break
      default:
        this.enableStateField(true)
        break
    }
  }

  enableStateField(enable) {
    this.stateFieldTarget.disabled = !enable
    if (this.init === false) {
      this.stateFieldTarget.value = ""
    }
    if (this.requiredFieldsValue) {
      this.stateFieldTarget.required = enable
    }
  }

  get selectedCountryType() {
    return this.countryFieldTarget.selectedOptions[0].dataset.countryType
  }
}
