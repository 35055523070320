import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'

export default class extends Controller {
  static targets = ["modal", "dialog", "title", "body", "autohide", "show"]

  connect() {
    this.modal = new Modal(this.modalTarget, {})
  }

  open(event) {
    event.preventDefault()
    event.stopPropagation()

    if (event.currentTarget.dataset.title !== undefined) {
      this.titleTarget.textContent = event.currentTarget.dataset.title
    } else {
      this.titleTarget.textContent = event.currentTarget.textContent
    }

    if (event.currentTarget.dataset.modalSize !== undefined) {
      this.dialogTarget.classList.remove("modal-lg")
      this.dialogTarget.classList.add(event.currentTarget.dataset.modalSize)
    }

    fetch(event.currentTarget.href)
      .then(response => response.text())
      .then(html => {
        this.bodyTarget.innerHTML = html
        this.modal.show()
      })
  }

  hide() {
    this.modal.hide()
  }

  autohideTargetConnected() {
    this.hide()
  }

  showTargetConnected(target) {
    if (target.dataset.title !== undefined) {
      this.titleTarget.textContent = target.dataset.title
    }

    if (target.dataset.url !== undefined) {
      fetch(target.dataset.url)
        .then(response => response.text())
        .then(html => {
          this.bodyTarget.innerHTML = html
          this.modal.show()
        })
    }
  }
}
