import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["countrySelect", "stateSelect", "zipcodeInput"];

	connect() {
		this.disableStateSelect(this.countrySelectTarget.value);
	}

	selectCountry(event) {
		const countryId = event.target.value;

		this.disableStateSelect(countryId);
	}

	disableStateSelect(countryId) {
		const options = this.stateSelectTarget.querySelectorAll(`option[data-country-id="${countryId}"]`);
		const requiredLabel = document.getElementById("state-required-label");
		const requiredworkLabel = document.getElementById("work-state-label");

		if (options.length == 0) {
			this.stateSelectTarget.value = "";
			this.stateSelectTarget.setAttribute("disabled", true);
			requiredLabel?.classList.add("hidden");
			requiredworkLabel?.classList.add("hidden");
		} else {
			this.stateSelectTarget.removeAttribute("disabled");
			requiredLabel?.classList.remove("hidden");
			requiredworkLabel?.classList.remove("hidden");
		}
	}

	formatZipcode() {
		const countryId = this.countrySelectTarget.value;
		const zipcode = this.zipcodeInputTarget.value;

		// 233 is the country ID for United States
		if (countryId == 233) {
			this.zipcodeInputTarget.setAttribute("maxlength", 5);
			this.zipcodeInputTarget.value = zipcode.replace(/\D/g, "").substring(0, 5);
		} else {
			this.zipcodeInputTarget.setAttribute("maxlength", 9);
			this.zipcodeInputTarget.value = zipcode.replace(/\W/g, "").substring(0, 9);
		}
	}
}
