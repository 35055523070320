import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["parent", "child", "selectedIds", "declinedIds"];

	connect() {
		if (this.parentTarget) {
			this.childTargets.map((x) => (x.checked = false));
			this.parentTarget.checked = false;
		}
	}

	toggleChildren() {
		if (this.parentTarget.checked) {
			this.childTargets.map((x) => (x.checked = true));
		} else {
			this.childTargets.map((x) => (x.checked = false));
		}
	}

	toggleParent() {
		if (this.childTargets.map((x) => x.checked).includes(false)) {
			this.parentTarget.checked = false;
		} else {
			this.parentTarget.checked = true;
		}
	}

	acceptInvites() {
		if (this.checked.length == 0) {
			return;
		}

		const acceptedIds = [];
		this.checked.forEach((row) => acceptedIds.push(row.value));
		this.selectedIdsTarget.value = acceptedIds;
	}

	declineInvites() {
		if (this.checked.length == 0) {
			return;
		}

		const declinedIds = [];
		this.checked.forEach((row) => declinedIds.push(row.value));
		this.declinedIdsTarget.value = declinedIds;
	}

	get checked() {
		return this.childTargets.filter((checkbox) => checkbox.checked);
	}
}
