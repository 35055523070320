import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	connect() {
		if (!this.element.value.startsWith("2")) {
			this.element.value = "2";
		}
		this.element.addEventListener("input", this.formatFincenId.bind(this));
	}

	formatFincenId() {
		const alert = document.querySelector(".company-fincen-id-alert");

		alert.classList.remove("hidden");

		let cleanedInput = this.element.value.replace(/\D/g, "");
		if (cleanedInput.length === 0) {
			this.element.value = "2";
			return;
		}

		// this ensures the input always starts with "2":
		if (cleanedInput[0] !== "2") {
			cleanedInput = "2" + cleanedInput;
		}

		cleanedInput = cleanedInput.substring(0, 12);
		let formattedFincenId = cleanedInput.replace(/^(\d{4})(\d{4})/, "$1-$2-");
		formattedFincenId = formattedFincenId.substring(0, 14);

		if (formattedFincenId.length === 14) {
			alert.classList.add("hidden");
		}

		this.element.value = formattedFincenId;
	}
}
