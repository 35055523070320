import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	connect() {
		this.element.addEventListener("input", this.formatFincenId.bind(this));
	}

	formatFincenId() {
		const addBOorOfficerDiv = document.querySelector("#member-by-fincen-id");
		const addBOorOfficerSectionIsVisible = addBOorOfficerDiv?.classList.contains("show");
		const alert = addBOorOfficerSectionIsVisible ? document.querySelector(".fincen-id-alert-bo") : document.querySelector(".fincen-id-alert");

		alert?.classList.remove("hidden");

		let cleanedInput = this.element.value.replace(/\D/g, "");
		if (cleanedInput.length === 0) {
			this.element.value = "";
			return;
		}

		cleanedInput = cleanedInput.substring(0, 12);
		let formattedFincenId = cleanedInput.replace(/^(\d{4})(\d{4})/, "$1-$2-");
		formattedFincenId = formattedFincenId.substring(0, 14);

		if (formattedFincenId.length === 14) {
			alert?.classList.add("hidden");
		}

		this.element.value = formattedFincenId;
	}
}
