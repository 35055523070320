import CheckboxSelectAll from "./checkbox_select_all_controller"

// Connects to data-controller="bulk-companies"
export default class extends CheckboxSelectAll {
  connect() {
    super.connect()
  }

  checkReadyToFile(e) {
    e.preventDefault()

    this.checkboxTargets.forEach((checkbox) => checkbox.checked = false)
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.dataset.readyToFile === "true") {
        checkbox.checked = true
      }
    })
    this.refresh()
  }

  checkFileUpdate(e) {
    e.preventDefault()

    this.checkboxTargets.forEach((checkbox) => checkbox.checked = false)
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.dataset.fileUpdate === "true") {
        checkbox.checked = true
      }
    })
    this.refresh()
  }

  checkIncompleteInfo(e) {
    e.preventDefault()

    this.checkboxTargets.forEach((checkbox) => checkbox.checked = false)
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.dataset.incompleteInfo === "true") {
        checkbox.checked = true
      }
    })
    this.refresh()
  }
}
