import { Controller } from "@hotwired/stimulus"

/**
 * @example
 * <select
 *   id="country_id"
 *   data-controller="country-select"
 *   data-country-select-state-select-outlet="#state_id"
 *   data-action="country-select#updateStateSelect"
 * >
 *   <option value="" label=" "></option>
 *   <option value="1">Afghanistan</option>
 *   <option value="2" selected="selected">United States</option>
 * </select>
 * <select
 *   id="state_id"
 *   data-controller="state-select"
 *   data-state-select-country-select-outlet="#country_id"
 *   data-action="state-select#rememberSelection"
 * >
 *   <option value="" label=" "></option>
 *   <option value="1" data-country-id="1">Badakhshan</option>
 *   <option value="2" data-country-id="1">Badghis</option>
 *   <option value="3" data-country-id="2">Alabama</option>
 *   <option value="4" data-country-id="2" selected="selected">New York</option>
 * </select>
 */
export default class extends Controller {
  static outlets = ["country-select"]

  initialize() {
    this.#createOptionsByCountry()
    this.selectedStateByCountry = {}
  }

  get country() {
    return this.countrySelectOutletElement.value ?? ""
  }

  countrySelectOutletConnected() {
    this.rememberSelection()
    this.updateOptions()
  }

  rememberSelection() {
    this.selectedStateByCountry[this.country] = this.element.value
  }

  restoreSelection() {
    this.element.value = this.selectedStateByCountry[this.country] ?? ""
    this.element.value = this.element.value // workaround nonexistent option
  }

  updateOptions() {
    this.element.innerHTML = this.optionsByCountry[this.country] ?? this.optionsByCountry[""]
    this.restoreSelection()
  }

  #createOptionsByCountry() {
    const byCountry = {}
    this.element.querySelectorAll('option').forEach(option => {
      (byCountry[option.dataset.countryId ?? ""] ??= []).push(option.outerHTML)
    })
    const shared = byCountry[""] ?? []
    Object.entries(byCountry).forEach(([country, options]) => {
      if (country !== "") {
        options = shared.concat(options)
      }
      byCountry[country] = options.join("\n")
    })
    this.optionsByCountry = byCountry
  }
}
