import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	connect() {
		this.element.addEventListener("change", this.showDateRangeField.bind(this));

		this.startDateInput = document.querySelector('[name="start_date"]');
		this.endDateInput = document.querySelector('[name="end_date"]');
		this.errorField = document.getElementById("date-range-error");
		this.submitButton = document.getElementById("custom-date-submit-btn");

		this.startDateInput.addEventListener("input", this.validateDates.bind(this));
		this.endDateInput.addEventListener("input", this.validateDates.bind(this));

		this.validateDates();
	}

	showDateRangeField() {
		let dateRangeField = document.getElementById("date-range-field");

		if (!dateRangeField) {
			return;
		}

		if (this.element.value === "custom") {
			dateRangeField.classList.remove("hidden");
		} else {
			dateRangeField.classList.add("hidden");
			this.clearError();
			this.enableSubmit();
		}
	}

	validateDates() {
		const startDate = new Date(this.startDateInput.value);
		const endDate = new Date(this.endDateInput.value);

		if (endDate <= startDate) {
			this.showError("End date must be greater than start date.");
			this.disableSubmit();
		} else {
			this.clearError();
			this.enableSubmit();
		}
	}

	showError(message) {
		this.errorField.textContent = message;
		this.errorField.style.display = "block";
	}

	clearError() {
		this.errorField.style.display = "none";
		this.errorField.textContent = "";
	}

	disableSubmit() {
		this.submitButton.disabled = true;
	}

	enableSubmit() {
		this.submitButton.disabled = false;
	}
}
